import './App.css';
import LandingPage from '../pages/landingPage/LandingPage';
import {Route} from 'react-router';
import {Helmet} from 'react-helmet';
import LoginPage from '../pages/loginPage/LoginPage';
import {selectGigyaAccount, selectUserProfile, setGigyaAccount, setUserProfile} from '../store/account/accountSlice';
import {useAppDispatch} from '../store/store';
import Header from './components/header/Header';
import CasesPage from '../pages/casesPage/CasesPage';
import CaseDetailsPage from '../pages/casesPage/CaseDetailsPage';
import {COLORS, globalStyles, LoadingIndicator, SiteFooter} from '@laerdal/life-react-components';
import InvoicesPage from '../pages/ordersinvoices/invoicePage/InvoicesPage';
import InvoiceDetailsPage from '../pages/ordersinvoices/invoicePage/InvoiceDetailsPage';
import ShipmentsPage from '../pages/ordersinvoices/shipmentPage/ShipmentsPage';
import ShipmentDetailsPage from '../pages/ordersinvoices/shipmentPage/ShipmentDetailsPage';
import OrderDetailsPage from '../pages/ordersinvoices/orderPage/OrderDetailsPage';
import OrdersPage from '../pages/ordersinvoices/orderPage/OrdersPage';
import AppointmentsPage from '../pages/appointmentPage/AppointmentsPage';
import AuthApi from '../services/api/AuthApi';
import React, {useEffect, useState} from 'react';
import {AuthRoute} from './components/route/AuthRoute';
import UserApi from '../services/api/UserApi';
import styled, {createGlobalStyle} from 'styled-components';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import CaseApi from '../services/api/CaseApi';
import InvoicesApi from '../services/api/InvoicesApi';
import ShipmentsApi from '../services/api/ShipmentsApi';
import OrdersApi from '../services/api/OrdersApi';
import {setFeatures, setCountries} from '../store/features/featuresSlice';
import {useFeatures} from '../hooks/Features';
import {FeatureNames} from '../model/constants/FeatureConstants';
import SettingsApi from '../services/api/SettingsApi';
import moment from 'moment';
import {SingedInOnBehalfBanner} from './components/banners/SingedInOnBehalfBanner';
import SAMLPage from '../pages/samlPage/SAMLPage';
import AppointmentsApi from '../services/api/AppointmentsApi';
import AppointmentDetailsPage from '../pages/appointmentPage/AppointmentDetailsPage';
import {Navigate, Routes, useLocation, useMatch} from 'react-router-dom';
import {GuardedRoute} from './components/route/GuardedRoute';
import {PermissionsType, usePermissions} from '../hooks/Permissions';
import LicenseDetailsPage from '../pages/licenseManagementPage/LicenseDetailsPage';
import LicenseListPage from '../pages/licenseManagementPage/LicenseListPage';
import {OverviewPage} from '../pages/overviewPage/OverviewPage';
import {UserManagementPage} from '../pages/usersPage/UserManagementPage';
import {ProfilePage} from '../pages/profilePages/ProfilePage';
import {LanguageAndRegionPage} from '../pages/profilePages/LanguageAndRegionPage';
import {ChangePasswordPage} from '../pages/profilePages/ChangePasswordPage';
import {UserDetailsPage} from '../pages/profilePages/UserDetailsPage';
import {CompanyRolesPage} from '../pages/profilePages/CompanyRolesPage';
import {UserOrganizationsPage} from '../pages/profilePages/UserOrganizationsPage';
import AddressPage from '../pages/address/AddressPage';
import PaymentPage from '../pages/payment/PaymentPage';
import {UserPermissionsPage} from '../pages/userPermissions/UserPermissionsPage';
import {AccessDeniedPage} from "../pages/accessDenied/AccessDenied";
import GoogleAnalyticsService from "../services/helpers/googleAnalytics";
import HotjarService from "../services/helpers/hotjar";
import RedirectPage from '../pages/ordersinvoices/RedirectPage';
import AppointmentBookingPage from '../pages/appointmentPage/AppointmentBookingPage';
import posthog from 'posthog-js';
import {LicenseAmendmentPage} from "../pages/licenseManagementPage/LicenseAmendmentPage";
import {LicenseAmendmentOptions} from "../pages/licenseManagementPage/components/LicenseAmendmentOptions";
import {LicenseAmendmentConfirm} from "../pages/licenseManagementPage/components/LicenseAmendmentConfirm";
import {LicenseAmendmentSuccessPage} from "../pages/licenseManagementPage/LicenseAmendmentSuccessPage";
import {LicenseAmendmentCancel} from "../pages/licenseManagementPage/components/LicenseAmendmentCancel";
import {LicenseAmendmentPayment} from "../pages/licenseManagementPage/components/LicenseAmendmentPayment";
import {LicenseAmendmentRenew} from "../pages/licenseManagementPage/components/LicenseAmendmentRenew";
import { User } from '../model/dto/userProfile/user';
import { Industry } from '../model/dto/userProfile/industry';

const LoadingContainer = styled.div`
  padding: 50px;
`;


const GlobalStyle = createGlobalStyle`
  ${globalStyles}
  body {
    background-color: ${COLORS.neutral_50};
  }
`;
const GlobalStyleProxy: any = GlobalStyle;



const App = () => {

  const dispatch = useAppDispatch();
  let location = useLocation(); // new

  const {t, i18n} = useTranslation('Common');

  const [checkFeature, isFeaturesInitialized] = useFeatures();
  const {hasPermissions} = usePermissions();

  const [oneTrustChanged, setOneTrustChanged] = useState(0);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(process.env.REACT_APP_DEFAULT_LANGUAGE);

  const account = useSelector(selectGigyaAccount);
  const userProfile = useSelector(selectUserProfile);
  const hasOrganization = !!userProfile?.currentOrganization;

  ///////////////////////////////// POSTHOG

  const configurePosthog = (userParam : User | undefined) => {  
    try
    {
      //@ts-ignore
      if(hasOneTrustConsent()){
        //posthog settings
        
        posthog.config.disable_surveys = false;
  
        if(!!userParam){
          //@ts-ignore
          posthog.identify?.(
            userParam.id,
            {
              org_role: userParam.currentOrganization?.members.find(member => member.user.id === userParam.id)?.permissions.findIndex(permission => permission.permissionId === 'account.owner') ? 'admin' : 'user', 
              language: language ? language : 'en',
              user_profile: userParam.companyRoles?.map(role => role.name).join('/'),
              test_user: userParam.email.indexOf('@laerdal.') > -1
            }
          );
          posthog.capture("User SignedIn");
    
          if(userParam.currentOrganization)
          {
            var industryString = ''
            try {
              industryString = Industry[userParam.currentOrganization.industry]
            } catch (error) {
              industryString = '';
            }

            //@ts-ignore
            posthog.group?.(
              'organisation', 
              userParam.currentOrganization.customerNo ?? userParam.currentOrganization.id,
              {
                orgId: userParam.currentOrganization.id,
                customerNumber: userParam.currentOrganization.customerNo,
                industry: industryString,
                country: userParam.currentOrganization.address.country.name
              });
          }
        }
      }
      else {
        posthog.config.disable_surveys = true;
      }
    }
    catch (error)
    {
      console.error('posthog config error', error);
    }
  }

  useEffect(() => {
    //@ts-ignore
    posthog.init(process.env.REACT_APP_POSTHOG, {
      //@ts-ignore
      api_host: 'https://anltcs.laerdal.com',
      ui_host: 'https://eu.posthog.com',
      person_profiles : 'identified_only',
      autocapture: true,
      disable_surveys: hasOneTrustConsent() ? false : true
    });
    //@ts-ignore
    if (!window.posthog) {
      //@ts-ignore
      window.posthog = posthog;
    }

    configurePosthog(userProfile);

    //@ts-ignore
    window.OneTrust?.OnConsentChanged(() => { 
      setOneTrustChanged(Math.random());
    });
  }, []); 

  // posthog
  useEffect(() => {
    posthog.reset();
    configurePosthog(userProfile);
  }, [userProfile, oneTrustChanged]);

  const hasOneTrustConsent = () => {
    //@ts-ignore
    console.log('hasOneTrustConsent', window.OnetrustActiveGroups?.indexOf('C0002') >= 0);
    //@ts-ignore
    return window.OnetrustActiveGroups?.indexOf('C0002') >= 0
  };
  

  ///////////////////////////////// POSTHOG

  const onLoginHandler = (eventObj: any) => {
    dispatch(
      setGigyaAccount({
        country: eventObj.data?.organization?.country,
        dateRegistered: eventObj.registered,
        email: eventObj.profile.email,
        firstName: eventObj.profile.firstName,
        identityId: eventObj.UID,
        lastLogin: eventObj.lastLogin,
        lastName: eventObj.profile.lastName,
        locale: eventObj.profile.locale ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
        market: eventObj.data.commerce?.market,
      }),
    );

    return UserApi.getUser()
            .then((user) => {
              dispatch(setUserProfile(user));
            })
  };

  function onLogoutHandler() {
    //@ts-ignore
    posthog.reset?.();
    window.location.reload?.();
  }

  useEffect(() => {
    SettingsApi.GetFeatures()
      .then((data) => dispatch(setFeatures(data)))
      .catch((e) => dispatch(setFeatures([])));
  }, []);

  useEffect(() => {
    if (isFeaturesInitialized) {
      // @ts-ignore
      window.gigya.accounts.addEventHandlers({
        onLogout: onLogoutHandler,
      });

      //SSO
      AuthApi.retrieveAndStoreJWT()
        .then(check => check 
                        && AuthApi.retrieveAccountInfo()
                            .then(onLoginHandler))
        .finally(() =>{
          setLoading(false)
        }
      )
    }
  }, [isFeaturesInitialized]);

  React.useEffect(() => { // new
    posthog.capture('$pageview')
  }, [location.pathname]);

  useEffect(
    () => {
      const correctLocale = getCorrectLocale(account?.locale ?? process.env.REACT_APP_DEFAULT_LANGUAGE);
      i18n.changeLanguage(correctLocale);

      SettingsApi.GetCountries()
        .then(a =>
        {
            dispatch(setCountries(a)); 
            return a.find(c => c.codeAlpha2 === account?.country)?.locale ?? process.env.REACT_APP_DEFAULT_LANGUAGE
        }
        )
        .then(locale => {
          moment.locale(locale);
        });

      GoogleAnalyticsService.StartAnalytics(account);
      HotjarService.StartAnalytics(account);
    },
    [account],
  );

  useEffect(
    () => {
      i18n.on('languageChanged', (lang) => {
        window.globalNav.setLocale(lang);
        setLanguage(lang);
      });
    },
    [],
  );

  // prefetch the cases page
  useEffect(() => {
    CaseApi.ClearCache();
    InvoicesApi.ClearCache();
    ShipmentsApi.ClearCache();
    OrdersApi.ClearCache();
    AppointmentsApi.ClearCache();
  }, [userProfile, language]);

  
  useEffect(() => {
    if(userProfile)
    {
      let div = document.getElementById('onetrust-consent-sdk');
      if(div) 
        div.style.setProperty('display', 'block', 'important');
    }
  }, [userProfile]);

  const match = useMatch('/licenses/:id');

  return (
    <>
      <GlobalStyleProxy/>
      <Helmet defaultTitle={t('Laerdal Account')} titleTemplate={`%s - Laerdal ${t('Account')}`}>
        <meta name="description" content={t(`All the settings for your Laerdal account`)}></meta>
      </Helmet>
      <Header/>

      <React.Suspense fallback={<LoadingContainer><LoadingIndicator/></LoadingContainer>}>
        <SingedInOnBehalfBanner/>
        {
          loading && <LoadingContainer><LoadingIndicator/></LoadingContainer>
        }
        {
          !loading &&
          <Routes>
            <Route element={<AuthRoute/>}>
              <Route path="/" element={<LandingPage/>}/>
              <Route path="/access-denied" element={<AccessDeniedPage/>}/>
              <Route path="/overview" element={<GuardedRoute guard={() => hasOrganization}/>}>
                <Route path="" element={<OverviewPage/>}/>
              </Route>
              <Route path="/addresses" element={
                <GuardedRoute guard={() =>
                  hasOrganization
                  && hasPermissions(PermissionsType.AccountOwner)
                }/>}>
                <Route path="" element={<AddressPage/>}/>
              </Route>
              <Route path="/billing" element={
                <GuardedRoute guard={() =>
                  hasOrganization
                  && hasPermissions(PermissionsType.AccountOwner)}/>}>
                <Route path="" element={<PaymentPage/>}/>
              </Route>

              <Route path="/profile">
                <Route path="" element={<ProfilePage/>}/>
                <Route path="language-region" element={<LanguageAndRegionPage/>}/>
                <Route path="password" element={<ChangePasswordPage/>}/>
                <Route path="edit" element={<UserDetailsPage/>}/>
                <Route path="roles" element={<CompanyRolesPage/>}/>
                {/* <Route path="settings" element={<UserSettingsPage/>}/> */}
                <Route path="organizations" element={<UserOrganizationsPage/>}/>
              </Route>

              <Route path="/cases" >
                <Route path="create" element={<CasesPage/>}/>
                <Route path="" element={<CasesPage/>}/>
                <Route path="details/:caseNumber" element={<CaseDetailsPage/>}/>
              </Route>

              <Route path="/ordersinvoices">
                <Route path="" element={<Navigate to="/ordersinvoices/orders" replace/>}/>
                <Route path="orders">
                  <Route path="" element={<OrdersPage/>}/>
                  <Route path="details/:orderNumber" element={<OrderDetailsPage/>}/>
                </Route>

                <Route path={'invoices'}>
                  <Route path="" element={<InvoicesPage/>}/>
                  <Route path="details/:invoiceNumber" element={<InvoiceDetailsPage/>}/>
                </Route>

                <Route path={'shipments'}>
                  <Route path="" element={<ShipmentsPage/>}/>
                  <Route path="details/:shipmentNumber" element={<ShipmentDetailsPage/>}/>
                </Route>
              </Route>

              {/* 
                Keep these routs for compatibility from other sites 
                Connect and the Webshop use old routes
              */}
              <Route path="/orders">
                <Route path="" element={<Navigate to="/ordersinvoices/orders" replace/>}/>
                <Route path="details/:id" element={<RedirectPage path="/ordersinvoices/orders/details"/>}/>
              </Route>
              <Route path={'/invoices'}>
                <Route path="" element={<Navigate to="/ordersinvoices/invoices" replace/>}/>
                <Route path="details/:id" element={<RedirectPage path="/ordersinvoices/invoices/details"/>}/>
              </Route>
              <Route path={'/shipments'}>
                <Route path="" element={<Navigate to="/ordersinvoices/shipments" replace/>}/>
                <Route path="details/:id" element={<RedirectPage path="/ordersinvoices/shipments/details"/>}/>
              </Route>

              <Route path="/saml" element={<GuardedRoute guard={() => hasOrganization}/>}>
                <Route path="" element={<SAMLPage/>}/>
              </Route>
              <Route path="/users" element={<GuardedRoute guard={() => hasOrganization}/>}>
                <Route path=":page" element={<UserManagementPage/>}/>
                <Route path="" element={<UserManagementPage/>}/>
                <Route path="details/:type"
                       element={<GuardedRoute guard={() => hasPermissions(PermissionsType.ManageAccess)}/>}>
                  <Route path="" element={<UserPermissionsPage/>}/>
                </Route>
              </Route>
              <Route path="licenses"
                     element={
                       <GuardedRoute guard={() => hasPermissions(PermissionsType.Subscription, match?.params?.id)}/>
                     }>
                <Route path=":id/amendment/:orderId" element={<LicenseAmendmentSuccessPage/>}/>
                <Route path=":id" element={<LicenseDetailsPage/>}/>
                <Route path=":id/edit" element={<LicenseAmendmentPage/>}>
                  <Route path="payment" element={<LicenseAmendmentPayment/>}/>
                  <Route path="cancel" element={<LicenseAmendmentCancel/>}/>
                  <Route path="renew" element={<LicenseAmendmentRenew/>}/>
                  <Route path="renew/confirm" element={<LicenseAmendmentConfirm/>}/>
                  <Route path="options" element={<LicenseAmendmentOptions/>}/>
                  <Route path="options/confirm" element={<LicenseAmendmentConfirm/>}/>
                  <Route path="*" element={<Navigate to="options" replace={true}/>}/>
                </Route>
                <Route path="" element={<LicenseListPage/>}/>
              </Route>

              <Route path="appointments/details/:appointmentNumber/book/:s" element={<AppointmentBookingPage/>}/>
              <Route path="appointments/details/:appointmentNumber/book" element={<AppointmentBookingPage/>}/>
              <Route path="appointments"
                     element={
                       <GuardedRoute guard={() =>
                         hasPermissions(PermissionsType.ViewAppointments)}/>
                     }>
                <Route path="" element={<AppointmentsPage/>}/>
                <Route path="details/:appointmentNumber" element={<AppointmentDetailsPage/>}/>
              </Route>
            </Route>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="*" element={<Navigate to={'/'}/>}/>
          </Routes>
        }
      </React.Suspense>

      <SiteFooter
        sitename={t('Account')}

        copyrightText={t('Copyright ©') + ' ' + new Date().getFullYear() + ' ' + t('Laerdal Medical. All Rights Reserved.')}
        id={'footer'}
        bottomLinks={[{label: t('Privacy Policy'), to: 'https://laerdal.com/support/customer-service/privacy-policy/'}]}
        toTopText={t('TOP')}/>
    </>
  );
};

export default App;

function getCorrectLocale(locale: string | undefined): string {
  let correctLocale = "en";
  if (locale?.toLocaleLowerCase()?.startsWith("da")) correctLocale = "da-DK";
  if (locale?.toLocaleLowerCase()?.startsWith("de")) correctLocale = "de-DE";
  if (locale?.toLocaleLowerCase()?.startsWith("en")) correctLocale = "en";
  if (locale?.toLocaleLowerCase()?.startsWith("es")) correctLocale = "es-ES";
  if (locale?.toLocaleLowerCase()?.startsWith("fi")) correctLocale = "fi-FI";
  if (locale?.toLocaleLowerCase()?.startsWith("fr")) correctLocale = "fr-FR";
  if (locale?.toLocaleLowerCase()?.startsWith("it")) correctLocale = "it-IT";
  if (locale?.toLocaleLowerCase()?.startsWith("ja")) correctLocale = "ja-JP";
  if (locale?.toLocaleLowerCase()?.startsWith("jp")) correctLocale = "ja-JP";
  if (locale?.toLocaleLowerCase()?.startsWith("ko")) correctLocale = "ko-KR";
  if (locale?.toLocaleLowerCase()?.startsWith("kr")) correctLocale = "ko-KR";
  if (locale?.toLocaleLowerCase()?.startsWith("nb")) correctLocale = "nb-NO";
  if (locale?.toLocaleLowerCase()?.startsWith("no")) correctLocale = "nb-NO";
  if (locale?.toLocaleLowerCase()?.startsWith("nl")) correctLocale = "nl-NL";
  if (locale?.toLocaleLowerCase()?.startsWith("pl")) correctLocale = "pl-PL";
  if (locale?.toLocaleLowerCase()?.startsWith("pt")) correctLocale = "pt-PT";
  if (locale?.toLocaleLowerCase()?.startsWith("br")) correctLocale = "pt-BR";
  if (locale?.toLocaleLowerCase()?.startsWith("sv")) correctLocale = "sv-SE";
  if (locale?.toLocaleLowerCase()?.startsWith("zh")) correctLocale = "zh-CN";

  return correctLocale;
}

