import {OrganizationService} from '../../../model/dto/organization/organizationService';
import {Service} from '../../../model/dto/userProfile/service';
import {Session} from '../../../model/dto/organization/session';
import {InstanceLimitType} from '../../../model/dto/userProfile/instanceLimitType';
import {ServiceHelper} from './ServiceHelper';
import {ServiceMemberItem} from '../types';
import {PermissionsType} from '../../../hooks/Permissions';
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import dayjs from "dayjs";
import {OrganizationMember} from '../../../model/dto/organization/organizationMember';
import {Subscription} from "../../../model/dto/organization/subscription";
import {SubscriptionStatus, VALID_SUBSCRIPTION_STATUSES} from "../../../model/dto/organization/subscriptionStatus";
import {TFunction} from "react-i18next";
import {SubscriptionPrice} from "../../../model/dto/organization/subscriptionPrice";
import {formatCurrency} from "../../../util/currency-helper";
import {PaymentType} from "../../../model/dto/organization/paymentType";
import {SubscriptionAmendment} from "../../../model/dto/organization/subscriptionAmendment";
import {SubscriptionAmendmentType} from "../../../model/dto/organization/subscriptionAmendmentType";
import {SubscriptionOptions} from "../../../model/dto/organization/subscriptionOptions";
import {SubscriptionBillingDetails} from "../../../model/dto/organization/subscriptionBillingDetails";

export class LicenseInformationHelper {
  static IsActive(subscription?: Subscription) {
    return !!subscription && VALID_SUBSCRIPTION_STATUSES.includes(subscription!.status!);
  }

  static CanAddUsers(service?: Service, subscription?: Subscription, members?: ServiceMemberItem[]) {
    if (!members || !subscription || !!service?.subscriptionManagementPath) return false;

    const isKnowledgeHub = service?.id?.toLocaleLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase();

    return !isKnowledgeHub && (
      service?.instanceLimitType == InstanceLimitType.Session ||
      service?.instanceLimitType == InstanceLimitType.Role ||
      !subscription?.maxSubscriptionInstances ||
      members?.length < subscription?.maxSubscriptionInstances);
  }

  static CanCancel(subscription?: Subscription, hasOwnerPermissions?: boolean) {
    const isLastDay =
      dayjs(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(dayjs());


    if (subscription?.status == SubscriptionStatus.Trial) {
      return hasOwnerPermissions &&
        (subscription.paymentType == PaymentType.Paddle || !!subscription.subscriptionNumber);
    } else {
      return !!subscription &&
        subscription.autoRenew &&
        subscription.status == SubscriptionStatus.Active &&
        !isLastDay &&
        (subscription.paymentType == PaymentType.Paddle || !!subscription.subscriptionNumber) &&
        hasOwnerPermissions;
    }
  }

  static CanRenew(subscription?: Subscription, hasOwnerPermissions?: boolean) {
    const isLastDay =
      dayjs(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(dayjs());

    if (!subscription) return false;

    if (!VALID_SUBSCRIPTION_STATUSES.includes(subscription.status)) return false;

    if (subscription?.status == SubscriptionStatus.Trial) return false;

    if (subscription?.autoRenew) return false;

    if (subscription?.billingInterval == BillingInterval.Monthly) return false;

    if (isLastDay) return false;

    if (!(subscription.paymentType == PaymentType.Paddle || !!subscription.subscriptionNumber)) return false;

    return hasOwnerPermissions;
  }

  static CanChange(subscription?: Subscription, hasOwnerPermissions?: boolean) {
    const isLastDay =
      dayjs(subscription?.expirationDate ?? subscription?.nextBillingDate)
        .startOf('day')
        .add(-1, "day")
        .isBefore(dayjs());

    return !!subscription &&
      subscription.status == SubscriptionStatus.Active &&
      !isLastDay &&
      (subscription.paymentType == PaymentType.Paddle || !!subscription.subscriptionNumber) &&
      hasOwnerPermissions;
  }

  static CanPurchase(subscription?: Subscription, hasOwnerPermissions?: boolean, hasConfiguration?: boolean) {
    return hasConfiguration &&
      hasOwnerPermissions &&
      (subscription?.status == SubscriptionStatus.Expired ||
        subscription?.status == SubscriptionStatus.Deactivated);
  }

  static Duration(subscription?: Subscription) {
    return subscription?.billingInterval === BillingInterval.FiveYear
      ? 60
      : subscription?.billingInterval === BillingInterval.ThreeYear
        ? 36
        : subscription?.billingInterval === BillingInterval.Monthly
          ? 1
          : dayjs(subscription!.expirationDate!).diff(dayjs(subscription!.startDate!), 'months');
  }

  static UserCount(service?: Service, serviceMembers?: ServiceMemberItem[], sessions?: Session[], organizationMembers?: OrganizationMember[]) {
    const isKnowledgeHub = service?.id?.toLocaleLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase();
    return isKnowledgeHub
      ? organizationMembers?.length || 0
      : service?.instanceLimitType == InstanceLimitType.Session
        ? sessions?.length || 0
        : serviceMembers?.length || 0;
  }

  static MaxUserCount(subscription?: Subscription) {
    return subscription?.maxSubscriptionInstances
  }

  static IsUnlimited(subscription?: Subscription) {
    return !subscription?.maxSubscriptionInstances;
  }

  static WillRenew(subscription?: Subscription) {
    if (!subscription) return false;

    if (!VALID_SUBSCRIPTION_STATUSES.includes(subscription.status)) return false;

    if (subscription?.status == SubscriptionStatus.Trial && subscription?.autoUpgradeTrial) return true;

    if (subscription.status == SubscriptionStatus.Canceled) return false;

    return subscription.autoRenew;
  }

  static IsExpired(subscription?: Subscription) {
    return subscription?.status === SubscriptionStatus.Expired ||
      subscription?.status === SubscriptionStatus.Deactivated;
  }

  static WillExpire(subscription?: Subscription) {
    return !this.WillRenew(subscription) && this.IsActive(subscription) && !!subscription?.expirationDate;
  }

  static RenewalDate(subscription?: Subscription) {
    return this.WillRenew(subscription)
      ? subscription?.nextBillingDate ?? subscription?.expirationDate
      : undefined;
  }

  static ExpiredOn(subscription?: Subscription) {
    return subscription?.status == SubscriptionStatus.Deactivated
      ? subscription?.deactivationDate
      : subscription?.actualExpiredDate ?? subscription?.expirationDate;
  }

  static DaysLeft(subscription?: Subscription) {
    return dayjs(subscription?.expirationDate).startOf('day').diff(dayjs().startOf('day'), 'days');
  }

  static FormatServicePlan(t: TFunction, service?: Service, subscription?: Subscription) {
    return `${service?.name} ${subscription?.plan.name}`;
  }

  static FormatPlanTier(t: TFunction, service?: Service, subscription?: Subscription) {
    return subscription?.status === SubscriptionStatus.Trial
      ? t('Trial')
      : (service?.availablePlans?.length ?? 0) > 1
        ? `${subscription?.plan.name} ${subscription?.tier.name}`
        : `${subscription?.tier.name}`
  }

  static FormatInstanceLimit(t: TFunction, service?: Service, subscription?: Subscription) {
    return service?.instanceLimitType == InstanceLimitType.Session
      ? t('{{count}} sessions', {count: subscription?.maxSubscriptionInstances})
      : subscription?.tier.individualLicensing
        ? t('{{count}} users', {count: subscription?.maxSubscriptionInstances})
        : t('1-{{users}} users', {users: subscription?.maxSubscriptionInstances});
  }

  static FormatLicenseOwner(t: TFunction, service?: Service, organizationService?: OrganizationService, members?: OrganizationMember[]) {
    let text = '';
    const isKnowledgeHub = service?.id?.toLocaleLowerCase() == process.env.REACT_APP_KNOWLEDGE_HUB_SERVICE_ID?.toLocaleLowerCase();

    if (isKnowledgeHub) {
      const owners = members?.filter(x => x.permissions.some(p => p.permissionId == PermissionsType.AccountOwner)) || []
      if (owners.length > 0)
        text = owners[0].user.email
      if (owners.length > 1)
        text = owners[0].user.email.concat(` + ${owners.length - 1} ${t('more...')}`);
    } else {
      text = ServiceHelper.FormatContactInfo(organizationService, ServiceHelper.GetOwnerRoles(service), t);
    }

    return text || t('No contact information');
  }

  static FormatPrice(t: TFunction, subscription: Subscription | undefined, price: SubscriptionPrice | undefined) {
    if (!subscription || !price) return undefined;
    const priceText =
      price.intervalTotal
        ? formatCurrency(price.intervalTotal, price.currency)
        : formatCurrency(price.total, price.currency);

    const billingIntervalText =
      subscription?.billingInterval === BillingInterval.FiveYear
        ? t('5 years')
        : subscription?.billingInterval === BillingInterval.ThreeYear
          ? t('3 years')
          : subscription?.billingInterval === BillingInterval.Yearly
            ? t('year')
            : t('month');
    return `${priceText} / ${billingIntervalText}`;
  }

  static FormatPriceExtended(t: TFunction, billingInterval: BillingInterval | undefined, price: number | undefined, currency: string | undefined) {
    if (billingInterval == undefined || price == undefined || currency == undefined) return undefined;

    const amount = formatCurrency(price!, currency);
    switch (billingInterval) {
      case BillingInterval.Monthly:
        return t('{{amount}} per month', {amount});
      case BillingInterval.Yearly:
        return t('{{amount}} per year', {amount});
      case BillingInterval.ThreeYear:
        return t('{{amount}} per 3 years', {amount});
      case BillingInterval.FiveYear:
        return t('{{amount}} per 5 years', {amount});
    }

  }

  static FormatExpiration(t: TFunction, subscription: Subscription | undefined) {
    return subscription?.status == SubscriptionStatus.Draft || subscription?.status == SubscriptionStatus.DraftTrial
      ? t('Future Activation')
      : subscription?.status === SubscriptionStatus.Expired
        ? t('Expired on {{date}}', {date: dayjs(subscription?.actualExpiredDate ?? subscription?.expirationDate).format('LL')})
        : subscription?.status === SubscriptionStatus.Deactivated
          ? t('Expired on {{date}}', {date: dayjs(subscription?.deactivationDate).format('LL')})
          : this.WillRenew(subscription)
            ? t('Renews on {{date}}', {date: dayjs(subscription?.nextBillingDate ?? subscription?.expirationDate).format('LL')})
            : t('Expires on {{date}}', {date: dayjs(subscription?.expirationDate).format('LL')});
  }

  static FormatStatus(t: TFunction, subscription: Subscription | undefined) {
    return subscription?.status == SubscriptionStatus.Canceled
      ? t('Active')
      : subscription?.status == SubscriptionStatus.Expired ||
      subscription?.status == SubscriptionStatus.Deactivated
        ? t('Expired')
        : subscription?.status == SubscriptionStatus.Draft || subscription?.status == SubscriptionStatus.DraftTrial
          ? t('Future Activation')
          : subscription?.status == SubscriptionStatus.Trial
            ? t('Trial')
            : subscription?.status == SubscriptionStatus.PastDue
              ? t('Past Due')
              : t('Active');
  }

  static FormatDaysLeft(t: TFunction, subscription: Subscription | undefined) {
    return t('{{count}} days remaining', {count: this.DaysLeft(subscription)})
  }

  static ManagementPath(organizationService: OrganizationService) {
    const hasExternalManagementUrl = !!organizationService?.subscriptionManagementPath;
    return hasExternalManagementUrl
      ? new URL(organizationService?.subscriptionManagementPath, organizationService?.serviceUrl).toString()
      : `/licenses/${organizationService?.id}`;
  }

  static HasExternalManagementUrl(organizationService: OrganizationService) {
    return !!organizationService?.subscriptionManagementPath;
  }

  static ServiceMembers(organizationService: OrganizationService) {
    const members = organizationService!.members?.map(a => ({type: 'member', member: a}) as ServiceMemberItem) ?? [];

    const invitations = organizationService!.invitations?.filter(a => a.isActive)
      ?.map(a => ({type: 'invitation', invitation: a} as ServiceMemberItem)) || [];

    return [...members, ...invitations];
  }

  static FormatTierDescription(t: TFunction, service: Service | undefined, planId: string | undefined, tierId: string | undefined, quantity: number | undefined) {
    if (!service || !planId || !tierId) return;
    const plan = service.availablePlans?.find(p => p.id === planId);
    const tier = plan?.tiers?.find(t => t.id === tierId);

    if (!tier) return '';

    if (tier.individualLicensing) {
      if (service.instanceLimitType != InstanceLimitType.Session) {
        return t('Number of users: {{users}}', {users: quantity});
      } else {
        return t('Number of seats: {{users}}', {users: quantity});
      }
    }

    const maxTierUsers = plan!.tiers.reduce((max, t) => t.maxSubscriptionInstances > max ? t.maxSubscriptionInstances : max, 0);

    let result = `${tier.name}`;

    if (service.instanceLimitType != InstanceLimitType.Session) {
      if (tier.maxSubscriptionInstances) {
        result += `(${t('1-{{users}} users', {users: tier.maxSubscriptionInstances})})`
      } else {
        result += `(${t('{{users}}+ users', {users: maxTierUsers})})`
      }
    } else {
      if (tier.maxSubscriptionInstances) {
        result += `(${t('1-{{users}} seats', {users: tier.maxSubscriptionInstances})})`
      } else {
        result += `(${t('{{users}}+ seats', {users: maxTierUsers})})`
      }
    }

    return result;
  }

  static FormatUpgradeDowngradeText(t: TFunction, service: Service | undefined, amendment: SubscriptionAmendment | undefined) {
    if (!service || !amendment) return '';

    const plan = service.availablePlans?.find(p => p.id === amendment.planId);
    const tier = plan?.tiers?.find(t => t.id === amendment.planTierId);

    if (!tier) return '';

    if (tier.individualLicensing) {
      if (service.instanceLimitType == InstanceLimitType.Session) {

        return amendment.maxSubscriptionInstances == 1
          ? t('1 seat', {amount: amendment.maxSubscriptionInstances})
          : t('{{amount}} seats', {amount: amendment.maxSubscriptionInstances});
      } else {
        return amendment.maxSubscriptionInstances == 1
          ? t('1 user', {amount: amendment.maxSubscriptionInstances})
          : t('{{amount}} users', {amount: amendment.maxSubscriptionInstances});
      }
    } else {
      return `${plan!.name} ${tier.name}`
    }
  }

  static WillUpgrade(amendment: SubscriptionAmendment | undefined) {
    return amendment &&
      (
        amendment.amendments & SubscriptionAmendmentType.Upgrade ||
        amendment.amendments & SubscriptionAmendmentType.QuantityIncrease
      );
  }

  static WillDowngrade(amendment: SubscriptionAmendment | undefined) {
    return amendment &&
      (
        amendment.amendments & SubscriptionAmendmentType.Downgrade ||
        amendment.amendments & SubscriptionAmendmentType.QuantityDecrease
      );
  }

  static WillChangeTerm(amendment: SubscriptionAmendment | undefined) {
    return amendment && amendment.amendments & SubscriptionAmendmentType.TermChange;
  }

  static FormatTermChangeText(t: TFunction, service: Service | undefined, amendment: SubscriptionAmendment | undefined) {
    if (!service || !amendment) return '';

    switch (amendment.billingInterval) {
      case BillingInterval.Monthly:
        return t('Monthly');
      case BillingInterval.Yearly:
        if (amendment.duration > 12) {
          return t('{{x}} Years, paid annually', {x: amendment.duration / 12});
        } else {
          return t('1 Year');
        }
      case BillingInterval.ThreeYear:
        return t('3 Years, prepaid');
      case BillingInterval.FiveYear:
        return t('5 Years, prepaid');
    }
  }

  static CanAmend(subscription: Subscription | undefined, amendment: SubscriptionAmendment | undefined) {
    if (!subscription) return false;
    if (subscription.billingInterval == BillingInterval.Monthly && subscription.status == SubscriptionStatus.Canceled) return false;

    if (!amendment) return true;

    // has a scheduled downgrade
    if ((amendment.amendments & SubscriptionAmendmentType.Downgrade) ||
      (amendment.amendments & SubscriptionAmendmentType.QuantityDecrease))
      return false;

    if ((amendment.amendments & SubscriptionAmendmentType.Upgrade) ||
      (amendment.amendments & SubscriptionAmendmentType.QuantityIncrease))
      return false;

    // has a scheduled term change from monthly to yearly or vice versa
    if ((amendment?.amendments & SubscriptionAmendmentType.TermChange) &&
      (subscription?.billingInterval == BillingInterval.Monthly || amendment?.billingInterval == BillingInterval.Monthly))
      return false;

    return true;
  }

  static IsCardExpired(billingDetails: SubscriptionBillingDetails | undefined) {
    return this.CardExpiryDate(billingDetails)! < new Date();
  }

  static CardExpiryDate(billingDetails: SubscriptionBillingDetails | undefined) {
    let expiryYear = +(billingDetails?.cardExpiryYear ?? 0);
    if (expiryYear < 100) {
      expiryYear += new Date().getFullYear() - new Date().getFullYear() % 100;
    }
    return !!billingDetails?.cardExpiryMonth ? new Date(expiryYear, +(billingDetails?.cardExpiryMonth ?? 0) - 1) : undefined;
  }

  static CanExpire(subscription: Subscription | undefined) {
    return subscription?.expirationDate || subscription?.nextBillingDate;
  }

  static ShouldAutoRenew(subscription: Subscription | undefined, options: SubscriptionOptions | undefined, service: Service | undefined) {
    const plan = service?.availablePlans?.find(p => p.id === options?.planId);
    const tier = plan?.tiers?.find(t => t.id === options?.planTierId);

    const wasBillingIntervalChanged =
      options?.billingInterval != subscription?.billingInterval ||
      options?.billingInterval != BillingInterval.Monthly &&
      options?.duration != dayjs(subscription?.expirationDate).diff(dayjs(subscription?.startDate), 'month');

    const wasQuantityDecreased = tier?.individualLicensing && (subscription?.maxSubscriptionInstances ?? 1) > (options?.maxSubscriptionInstances ?? 1);

    const wasDowngraded = (subscription?.tier?.upgradeOrder ?? 0) > (tier?.upgradeOrder ?? 0);

    return wasBillingIntervalChanged || wasDowngraded || wasQuantityDecreased;
  }

}
