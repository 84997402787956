import {
  BREAKPOINTS,
  Button,
  COLORS,
  Checkbox,
  DropdownButton,
  DropdownItem,
  LoadingIndicator,
  PageWidth,
  SearchBar,
  Size,
  Table,
  TableColumn,
  TablePagination,
  ToggleSwitch,
} from '@laerdal/life-react-components';
import {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import {CaseListItemDto} from '../../model/dto/cases/caseListItemDto';
import CaseApi from '../../services/api/CaseApi';
import PaginationHelper from '../../services/helpers/PaginationHelper';
import UserApi from '../../services/api/UserApi';
import {CaseStatuses} from '../../model/constants/CaseStatuses';
import styled from 'styled-components';
import {useDebouncedValue, useMediaMatch} from 'rooks';
import moment from 'moment';
import CaseStatus from './CaseStatus';
import {selectUserProfile} from '../../store/account/accountSlice';
import {useSelector} from 'react-redux';
import CaseCreateModal from "../_commonComponents/CaseCreateModal";

import {ListHeader, ListPreTitle, ListSubtitle, ListTitle, StyledPageWidth} from '../_commonComponents/StyledComponents';
import {PermissionsType, usePermissions} from "../../hooks/Permissions";
import OrganizationApi from '../../services/api/OrganizationApi';
import { EmptyPageBox } from '../_commonComponents/EmptyPageBox';

const SearchBarContainer = styled.div`
  display: flex;
  flex-basis: 100%;

  & > div{
    flex: 1;
  }
  ${BREAKPOINTS.MEDIUM}{
    flex-basis: 49%;
  }
`;
const StatusFilterContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  
  ${BREAKPOINTS.MEDIUM}{
    flex-basis: 49%;
  }
`;

const FilterMineContainer = styled.div`
  flex: 1;
  & > div{
    min-width: 48px;
  }
`;
const CreateCaseContainer = styled.div`
`;

const ToolBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  
  ${BREAKPOINTS.MEDIUM}{
    & > div:first-child {
      width: 50%;
    }
  }
`;

const TableContainer = styled.div`
  background: white;
  display: flex;
  background-color: ${COLORS.white};
  width: 100%;
  
  overflow-x: auto;
  &>div{
    width: 100%;
  }
`;

const CasesPage = () => {
  const {t, i18n} = useTranslation(['Cases', 'Common']);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUserProfile);

  const {hasPermissions} = usePermissions();

  const hasAccessToAllCases = hasPermissions(PermissionsType.ViewSupportCases);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [loading, setLoading] = useState<boolean>(true);
  const [statusNew, setStatusNew] = useState<boolean>(true);
  const [statusOpen, setStatusOpen] = useState<boolean>(true);
  const [statusAwaitsYourReply, setStatusAwaitsYourReply] = useState<boolean>(true);
  const [statusRepair, setStatusRepair] = useState<boolean>(true);
  const [statusSolved, setStatusSolved] = useState<boolean>(true);
  const [statusLabel, setStatusLabel] = useState<string>(t('All statuses'));
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

  const [isSfContact, setIsSfContact] = useState<boolean>(false);
  const [isSfOrg, setIsSfOrg] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [status, setStatus] = useState<CaseStatuses[]>([CaseStatuses.All]);
  const [filterMine, setFilterMine] = useState<boolean>(!hasAccessToAllCases);
  const [orderBy, setOrderBy] = useState<string>();
  const [orderType, setOrderType] = useState<string>();

  const columns = useMemo<TableColumn[]>(() => ([
    {
      key: 'caseNumber',
      name: t('Case Number'),
      width: 100,
      sortable: true,
    },
    {
      key: 'subject',
      name: t('Subject'),
      sortable: false,
    },
    {
      key: 'statusTranslated',
      name: t('Status'),
      sortable: false,
      type: 'custom',
      customContent: (row: CaseListItemDto, key: string) => <CaseStatus status={row.status}
                                                                        statusTranslated={row.statusTranslated}/>,
    },
    {
      key: 'contactName',
      name: t('Created by'),
      sortable: false,
    },
    {
      key: 'dateOpened',
      name: t('Date created'),
      sortable: true,
      width: 350,
      type: 'custom',
      customContent: (row: CaseListItemDto, key: string) => moment(row.dateOpened).format('ll LT'),
    },
  ]), [t]);

  const [searchTermDebounce] = useDebouncedValue(searchTerm, 500);

  const [pagination, setPagination] = useState<TablePagination>({
    currentPage: 0,
    from: 0,
    rowsPerPage: 20,
    to: 0,
    total: 0,
  } as TablePagination);

  const [cases, setCases] = useState<CaseListItemDto[]>([]);

  const loadCases = () => {
    const controller = new AbortController();
    setLoading(true);
    CaseApi.GetCases(
      pagination.rowsPerPage,
      pagination.currentPage * pagination.rowsPerPage,
      i18n.language,
      filterMine,
      status,
      orderBy,
      orderType,
      searchTermDebounce || '',
      controller.signal
    ).then((response) => {
        setCases(response.list);
        setPagination(PaginationHelper.GetCurrent(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
        if(response.list.length > 0) setHasItems(true);
        setInitialLoad(false);
      })
      .catch(() => {
        if (!controller.signal.aborted) {
          setCases([]);
          setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, 0));
        }
      }).finally(() => {
      if (!controller.signal.aborted) {
        setLoading(false);
      }
    });

    return () => controller.abort();
  };

  /**
   * Open create modal if user navigates to /cases/create
   * */
  useEffect(() => {
    location.pathname.endsWith('create') && setIsCreateModalOpen(true);
  }, [location.pathname]);

  useEffect(() => {
    UserApi.IsSfContact().then(isSfContact => {
      setIsSfContact(isSfContact);
    });
    OrganizationApi.IsSfOrganization().then(isSfOrganization => {
      setIsSfOrg(isSfOrganization);
    });
  }, []);

  useEffect(() => {
    setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, 0));
  }, [searchTermDebounce, filterMine, status]);

  useEffect(() => {
    if(statusAwaitsYourReply && statusNew && statusOpen && statusRepair && statusSolved){
      setStatus([CaseStatuses.All])
      setStatusLabel(t('All statuses'));
      return;
    } 
    
    let label = '';
    let values: CaseStatuses[] = [];
    if(statusNew) {
      label += t('New') + ', ';
      values.push(CaseStatuses.New);
    }
    if(statusOpen) {
      label += t('Open') + ', ';
      values.push(CaseStatuses.Open);
    }
    if(statusAwaitsYourReply) {
      label += t('Awaits your reply') + ', ';
      values.push(CaseStatuses.AwaitsYourReply);
    }
    if(statusRepair) {
      label += t('Repair') + ', ';
      values.push(CaseStatuses.Repair);
    }
    if(statusSolved) {
      label += t('Solved') + ', ';
      values.push(CaseStatuses.Resolved);
    }

    if(label.endsWith(', ')) label = label.substring(0, label.length - 2);

    if(label == '') label = t('None');
    if(values.length == 0) values.push(CaseStatuses.None);
    setStatusLabel(label);
    setStatus(values);

  }, [statusAwaitsYourReply, statusNew, statusOpen, statusRepair, statusSolved]);

  useEffect(() => loadCases(), [searchTermDebounce, filterMine, status, pagination.rowsPerPage, pagination.currentPage, orderBy, orderType]);

  const onPreviousPage = () => {
    setPagination(PaginationHelper.GetPrevious(pagination.currentPage, pagination.rowsPerPage, pagination.total));
  };

  const onNextPage = () => {
    setPagination(PaginationHelper.GetNext(pagination.currentPage, pagination.rowsPerPage, pagination.total));
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setPagination(PaginationHelper.GetInitial(newRowsPerPage, pagination.total));
  };

  const openCaseDetails = (row: CaseListItemDto) => {
    navigate(`details/${row.caseNumber}`);
  };

  const customContentDropdownFilterList = [
      {value: CaseStatuses.Open, customContent: <Checkbox label={t('New')} selected={statusNew} select={(value) => setStatusNew(value)}  />},
      {value: CaseStatuses.Open, customContent: <Checkbox label={t('Open')} selected={statusOpen} select={(value) => setStatusOpen(value)}  />},
      {value: CaseStatuses.AwaitsYourReply, customContent: <Checkbox label={t('Awaits your reply')} selected={statusAwaitsYourReply} select={(value) => setStatusAwaitsYourReply(value)}/>},
      {value: CaseStatuses.Repair, customContent: <Checkbox label={t('Repair')} selected={statusRepair} select={(value) => setStatusRepair(value)}/>},
      {value: CaseStatuses.Resolved, customContent: <Checkbox label={t('Solved')} selected={statusSolved} select={(value) => setStatusSolved(value)}/>},
  ];


  return (
    <>
      <Helmet>
        <title>{t('Cases')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <CaseCreateModal 
          isOpen={isCreateModalOpen} 
          onClose={() => {
            //@ts-ignore
            posthog.capture?.('CasesPage CreateCaseModal');
            setIsCreateModalOpen(false)
          }}
          afterSubmit={loadCases}/>
        <ListHeader>
          <ListPreTitle>{user?.currentOrganization?.name}</ListPreTitle>
          <ListTitle>{t('Support Cases')}</ListTitle>
          <ListSubtitle>{t('Follow conversations with Laerdal support teams.')}</ListSubtitle>
        </ListHeader>

        { initialLoad &&
              <LoadingIndicator/>
          }

          {!initialLoad && !hasItems &&
            <EmptyPageBox 
              title={t('Your cases')} 
              description={t('Support cases you have submitted will be displayed here.')}
              buttonText={ isSfContact && isSfOrg ? t('Create new case') : undefined} 
              buttonAction={  isSfContact && isSfOrg ? () => setIsCreateModalOpen(true) : undefined} 
              ilustrationPath='/assets/repair_equipment.svg' />
          }

          {!initialLoad && hasItems &&
            <>
           
              <ToolBarContainer>
                <SearchBarContainer>
                  <SearchBar id={'search'}
                            setSearchTerm={setSearchTerm}
                            enterSearch={() => {}}
                            size={isMediumScreen ? Size.Medium : Size.Small}
                            placeholder={t('Search for cases...')}
                            removeSearch={() => setSearchTerm('')}/>
                </SearchBarContainer>

                <StatusFilterContainer>
                  <DropdownButton
                    type={'text'}
                    items={customContentDropdownFilterList}
                    onClick={() => {}}
                    label={statusLabel}
                    keepLabel={true}
                  />
                </StatusFilterContainer>

                <FilterMineContainer>
                  {
                    hasAccessToAllCases &&
                    <ToggleSwitch id={'filterMine'}
                                  size={isMediumScreen ? Size.Medium : Size.Small}
                                  selected={filterMine}
                                  onToggle={(value) => {
                                    //@ts-ignore
                                    posthog.capture?.('CasesPage FilterMine',{
                                      value:value
                                    });
                                    setFilterMine(value)
                                  }}
                                  label={t('See only my cases')}/>
                  }
                  {
                    !hasAccessToAllCases &&
                    <div></div>
                  }
                </FilterMineContainer>
                {
                  isSfContact && isSfOrg && isMediumScreen &&
                  <CreateCaseContainer>

                    <Button testId={'create'}
                            id="create"
                            onClick={() => setIsCreateModalOpen(true)}>
                      {t('Create new case')}
                    </Button>
                  </CreateCaseContainer>
                }
              </ToolBarContainer>
              <TableContainer>
                <Table
                  noRowsLabel={t('There are no rows to display', {'ns': 'Common'})}
                  rowsPerPageLabel={t('Rows per page:', {'ns': 'Common'})}
                  columns={columns}
                  rows={cases}
                  remoteOperations={true}
                  pagination={pagination}
                  onRowsPerPageChange={(newRowsPerPage: number) => {
                    //@ts-ignore
                    posthog.capture?.('CasesPage RowsPerPage');
                    onRowsPerPageChange(newRowsPerPage)
                  }}
                  onNextPageClick={() => {
                    //@ts-ignore
                    posthog.capture?.('CasesPage NextPage');
                    onNextPage()
                  }}
                  onPreviousPageClick={() => {
                    //@ts-ignore
                    posthog.capture?.('CasesPage PreviousPage');
                    onPreviousPage()
                  }}
                  onTriggerSortingChange={(orderBy: string, orderType?: string) => {
                    //@ts-ignore
                    posthog.capture?.('CasesPage TableSort',{
                      orderBy:orderBy,
                      orderType:orderType
                    });
                    setOrderBy(orderBy);
                    setOrderType(orderType);
                  }}
                  showLoadingIndicator={loading}
                  onSelectionChange={(row: CaseListItemDto) => openCaseDetails(row)}
                  selectable={true}
                />
              </TableContainer>

              {
                isSfContact && isSfOrg && !isMediumScreen &&
                <CreateCaseContainer>

                  <Button testId={'create'}
                          size={Size.Small}
                          id="create"
                          onClick={() => setIsCreateModalOpen(true)}>
                    {t('Create new case')}
                  </Button>
                </CreateCaseContainer>
              }

</>
          }

      </StyledPageWidth>
    </>);
};

export default CasesPage;